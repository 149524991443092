import { combineReducers } from "redux";
import chatReducer from "../modules/Chat/reducers/chatReducer";
import socketReducer from "../modules/Socket/reducers/socketReducer";
import welcomeReducer from "../modules/Welcome/reducers/welcomeReducer";

const reducer = combineReducers({
    welcomeReducer: welcomeReducer,
    socketReducer: socketReducer,
    chatReducer: chatReducer,
});

export default reducer;