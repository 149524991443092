import React from "react";
import {connect} from "react-redux";
import TeacherComponent from "../components/TeacherComponent";

const TeacherContainer = (props) => {
    return (
        <TeacherComponent {...props} />
    );
}

const mapStateToProps = state => {
    return {
    }
}

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(TeacherContainer);