const namespace = 'LOGGED_USER';
export const LOGGED_USER = `LOGGED_USER_${namespace}`;

export const getLoggedUser = (socket, message, from, to) => {
    return function (dispatch) {
        dispatch({
            type: LOGGED_USER,
            payload: {}
        });
    }
}