import React, {useEffect, useState, useRef} from 'react';
import './App.css';
import Peer from "simple-peer";
import styled from "styled-components";
import {config} from "../../../config/config";
import ChatContainer from '../../Chat/containers/chatContainer';
import _ from "lodash";
// import socketCreator from "../../Socket/components/socketCreator";
import TeacherContainer from "../../TeacherBox/containers/TeacherContainer";
import {Redirect} from "react-router-dom";

const ContainerFluid = styled.div`
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const ColUser = styled.div`
    display: inline-block;
    width: 30%;
`;

const ColPlayer = styled.div`
    display: inline-block;
    width: 70%;
`;

const Center = styled.div`
    text-align: center;
    width: 100%;
`;


const Video = styled.video`
  border: 1px solid blue;
  width: 100%;
  max-height: 400px;
`;

// const socket = socketCreator;

const VideoComponent = (props) => {
    const [yourID, setYourID] = useState("");
    const [loggedUser, setLoggedUser] = useState({});
    const [users, setUsers] = useState({});
    const [stream, setStream] = useState();
    const [blockPhone, setBlockPhone] = useState(false);
    const [receivingCall, setReceivingCall] = useState(false);
    const [waitForAnswer, setWaitForAnswer] = useState(false);
    const [caller, setCaller] = useState("");
    const [answerPerson, setAnswerPerson] = useState("");
    const [callerName, setCallerName] = useState("");
    const [callerSurname, setCallerSurname] = useState("");
    const [callerSignal, setCallerSignal] = useState();
    const [callAccepted, setCallAccepted] = useState(false);
    const [callEnded, setCallEnded] = useState(false);
    const [muted, setMuted] = useState(false);
    const [hideVideoTrack, setHideVideoTrack] = useState(false);
    const [callingAudio] = useState(new Audio(config.FRONT + '/callingSignal.ogg'));
    const [ringing, setRinging] = useState(false);
    const [waitingSound, setWaitingSound] = useState(false);
    const [audio] = useState(new Audio(config.FRONT + '/call.mp3'));
    const [addressee, setAddressee] = useState("");
    const [errorOccurred, setErrorOccurred] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const userVideo = useRef();
    const partnerVideo = useRef();
    const connectionRef = useRef();

    useEffect(() => {
        if (props.socket) {
            props.socket.on("yourID", (id) => {
                setYourID(id);
                getLoggedUser(id);
            });

            console.log(props.socket);
            // props.socket.on("yourID", (id, user) => {
            //     console.log('yourId ', id);
            //     setYourID(id);
            //     setLoggedUser(user);
            // });

            props.socket.on("yourData", (data) => {
                setLoggedUser(data);
            });

            props.socket.on("allUsers", (users) => {
                setUsers(users);
            });

            props.socket.on("refreshUsers", (users) => {
                setUsers(users);
            });

            props.socket.on("hey", (data) => {
                console.log('hey');
                setReceivingCall(true);
                setCaller(data.from);
                setCallerName(data.name);
                setCallerSurname(data.surname);
                setCallerSignal(data.signal);
            });

            props.socket.on('lostUser', (id) => {
                console.log('lostUser: ', id);
                setReceivingCall(false);
                setWaitForAnswer(false);
                if (addressee === id || caller === id) {
                    console.log('caller: ', caller);
                    endCall(caller, true);
                    setErrorOccurred(true);
                    setErrorMessage('Połączenie zostało utracone. Spróbuj ponownie...');

                    // peerRef.current.destroy();
                    window.location.reload();
                }
            });

            props.socket.on('ping', () => {
                props.socket.emit('pong', {timestamp: (new Date()).getTime()});
            });
        }
    }, [props.socket]);

    useEffect(() => {
        props.socketInit();
        navigator.mediaDevices.getUserMedia({video: true, audio: true})
            .then((stream) => {
                setStream(stream);

                if (userVideo.current) {
                    userVideo.current.srcObject = stream;
                }
            });
    }, []);

    useEffect(() => {
        if (ringing) {
            audio.play();
        } else {
            audio.pause();
            audio.currentTime = 0;
        }
        audio.loop = true;
    }, [ringing]);

    useEffect(() => {
        if (waitingSound) {
            callingAudio.play();
        } else {
            callingAudio.pause();
            callingAudio.currentTime = 0;
        }
        callingAudio.loop = true;
    }, [waitingSound]);

    const callPeer = (id) => {
        setWaitForAnswer(true);
        setAnswerPerson(users[id]);
        setBlockPhone(true);
        setAddressee(id);
        setWaitingSound(true);

        const peer = new Peer({
            initiator: true,
            trickle: false,
            config: {
                iceServers: [
                    {
                        url: 'stun:global.stun.twilio.com:3478?transport=udp',
                        urls: 'stun:global.stun.twilio.com:3478?transport=udp'
                    },
                    {
                        url: 'turn:global.turn.twilio.com:3478?transport=udp',
                        username: '1b29b52b21ecf26c6a3b94a207157ff94006cc08f832c53b3df2b82b7d335a38',
                        urls: 'turn:global.turn.twilio.com:3478?transport=udp',
                        credential: 'kfO/023PcLwez1T4DU+gwEJvLpIgOjC7d9lwaP8tHY4='
                    },
                    {
                        url: 'turn:global.turn.twilio.com:3478?transport=tcp',
                        username: '1b29b52b21ecf26c6a3b94a207157ff94006cc08f832c53b3df2b82b7d335a38',
                        urls: 'turn:global.turn.twilio.com:3478?transport=tcp',
                        credential: 'kfO/023PcLwez1T4DU+gwEJvLpIgOjC7d9lwaP8tHY4='
                    },
                    {
                        url: 'turn:global.turn.twilio.com:443?transport=tcp',
                        username: '1b29b52b21ecf26c6a3b94a207157ff94006cc08f832c53b3df2b82b7d335a38',
                        urls: 'turn:global.turn.twilio.com:443?transport=tcp',
                        credential: 'kfO/023PcLwez1T4DU+gwEJvLpIgOjC7d9lwaP8tHY4='
                    }
                ]
            },
            // config: {
            //     iceServers: [
            //         {
            //             urls: "stun:numb.viagenie.ca",
            //             username: "sultan1640@gmail.com",
            //             credential: "98376683"
            //         },
            //         {
            //             urls: "turn:numb.viagenie.ca",
            //             username: "sultan1640@gmail.com",
            //             credential: "98376683"
            //         }
            //         // {urls: 'stun:stun.l.google.com:19302'},
            //         // {urls: 'stun:stun1.l.google.com:19302'},
            //         // {urls: 'stun:stun2.l.google.com:19302'},
            //         // {urls: 'stun:stun3.l.google.com:19302'},
            //         // {urls: 'stun:stun4.l.google.com:19302'},
            //         // {
            //         //     urls: "stun:numb.viagenie.ca",
            //         //     username: "sultan1640@gmail.com",
            //         //     credential: "98376683"
            //         // },
            //         // {urls: 'stun:stun01.sipphone.com'},
            //         // {urls: 'stun:stun.ekiga.net'},
            //         // {urls: 'stun:stun.fwdnet.net'},
            //         // {urls: 'stun:stun.ideasip.com'},
            //         // {urls: 'stun:stun.iptel.org'},
            //         // {urls: 'stun:stun.rixtelecom.se'},
            //         // {urls: 'stun:stun.schlund.de'},
            //         // {urls: 'stun:stunserver.org'},
            //         // {urls: 'stun:stun.softjoys.com'},
            //         // {urls: 'stun:stun.voiparound.com'},
            //         // {urls: 'stun:stun.voipbuster.com'},
            //         // {urls: 'stun:stun.voipstunt.com'},
            //         // {urls: 'stun:stun.voxgratia.org'},
            //         // {urls: 'stun:stun.xten.com'},
            //         // {
            //         //     urls: 'turn:numb.viagenie.ca',
            //         //     credential: 'muazkh',
            //         //     username: 'webrtc@live.com'
            //         // },
            //         // {
            //         //     urls: 'turn:192.158.29.39:3478?transport=udp',
            //         //     credential: 'JZEOEt2V3Qb0y27GRntt2u2PAYA=',
            //         //     username: '28224511:1379330808'
            //         // },
            //         // {
            //         //     urls: 'turn:192.158.29.39:3478?transport=tcp',
            //         //     credential: 'JZEOEt2V3Qb0y27GRntt2u2PAYA=',
            //         //     username: '28224511:1379330808'
            //         // }
            //     ]
            // },
            stream: stream,
        });

        peer.on("signal", data => {
            props.socket.emit("callUser", {userToCall: id, signalData: data, from: yourID, user: loggedUser})
        })

        peer.on("stream", stream => {
            if (partnerVideo.current) {
                partnerVideo.current.srcObject = stream;
            }
        });

        props.socket.on("callAccepted", signal => {
            setCallAccepted(true);
            setWaitingSound(false);
            peer.signal(signal);
        });

        connectionRef.current = peer;

        props.socket.on("callRejected", signal => {
            setCallAccepted(false);
            setWaitForAnswer(false);
            setWaitingSound(false);
            setAnswerPerson("");
            setBlockPhone(false);
        });

        props.socket.on("callEnded", signal => {
            setCallAccepted(false);
            setWaitForAnswer(false);
            setAnswerPerson("");
            setBlockPhone(false);
        });
    }

    const getLoggedUser = (id) => {
        if (props.uuid) {
            props.socket.emit('getLoggedUser', {id, uuid: props.uuid});
        }
    }

    const acceptCall = () => {
        setCallAccepted(true);
        setReceivingCall(false);
        setWaitForAnswer(false);
        setAddressee(caller);
        setRinging(false);
        const peer = new Peer({
            initiator: false,
            trickle: false,
            stream: stream,
        });
        peer.on("signal", data => {
            props.socket.emit("acceptCall", {signal: data, to: caller})
        })

        peer.on("stream", stream => {
            partnerVideo.current.srcObject = stream;
        });

        peer.signal(callerSignal);
        connectionRef.current = peer;
    }

    const rejectCall = () => {
        setCallAccepted(false);
        setReceivingCall(false);
        setWaitForAnswer(false);
        setAnswerPerson("");
        setAddressee("");
        let peer = new Peer({
            initiator: false,
            trickle: false,
            stream: stream,
        });

        peer.on("signal", data => {
            props.socket.emit("rejectCall", {signal: data, from: yourID, to: caller})
        });

        peer.signal(callerSignal);
        setRinging(false);
    }

    const endCall = (caller) => {
        setCallAccepted(false);
        setCallEnded(true);
        setReceivingCall(false);
        setWaitForAnswer(false);
        setAnswerPerson("");
        setAddressee("");

        let peer = new Peer({
            initiator: false,
            trickle: false,
            stream: stream,
        });
        peer.on("signal", data => {
            console.log(data);
            props.socket.emit("endCall", {signal: data, to: caller, from: yourID})
        });

        if (!errorOccurred) {
            peer.signal(callerSignal);
        }

        // connectionRef.current.destroy();
    }

    const muteAudio = () => {
        if (!muted) {
            stream.getAudioTracks()[0].enabled = false;
            setMuted(true);
        } else {
            stream.getAudioTracks()[0].enabled = true;
            setMuted(false);
        }
    }

    const hideVideo = () => {
        if (!hideVideoTrack) {
            stream.getVideoTracks()[0].enabled = false;
            setHideVideoTrack(true);
        } else {
            stream.getVideoTracks()[0].enabled = true;
            setHideVideoTrack(false);
        }
    }

    let incomingCall;
    if (receivingCall) {
        if (!callAccepted) {
            incomingCall = (
                <Center>
                    <h1>{callerName} {callerSurname} dzwoni do Ciebie</h1>
                    <button onClick={() => {
                        acceptCall();
                    }}>Odbierz
                    </button>
                    &nbsp;&nbsp;
                    <button onClick={() => {
                        rejectCall();
                    }}>Odrzuć
                    </button>
                </Center>
            );
            // setTimeout(() => {
            //     rejectCall();
            // }, 25000);
        }
    }

    let outgoingCall;
    if (waitForAnswer) {
        if (!callAccepted) {
            outgoingCall = (
                <Center>
                    <h3>Oczekiwanie na odbiór połączenia - dzwonisz
                        do: {answerPerson.user.name} {answerPerson.user.surname}</h3>
                </Center>
            );
        }
    }

    let endCallButton;
    if (callAccepted) {
        const socket = caller ? caller : yourID;
        // console.log('Twoje ID: ', yourID);
        // console.log('ID do kogo dzwonisz: ', socket);
        if (yourID !== socket) {
            endCallButton = (
                <Center>
                    <button onClick={() => endCall(socket)}>Zakończ rozmowę</button>
                </Center>
            );
        }
    }

    let usersContainer;
    if (!callAccepted && !errorOccurred && !_.isEmpty(loggedUser) && !loggedUser.isTeacher) {
        if (!_.isEmpty(users)) {
            usersContainer = (
                <TeacherContainer users={users} yourID={yourID} callPeer={callPeer} blockPhone={blockPhone}
                                  receivingCall={receivingCall} loggedUser={loggedUser}/>
            );
        }
    }

    if (_.isEmpty(loggedUser)) {
        // return <Redirect to={'/'}/>
        usersContainer = (
            <Row>
                <Center><h3>Nie jesteś zalogowany ;)</h3></Center>
            </Row>
        );
    }

    let somethingBad = '';
    if (errorOccurred) {
        somethingBad = (
            <Row>
                <Center>
                    <h4>Wystąpił:</h4>
                    <i>{errorMessage}</i>
                </Center>
                <Center>
                    <button onClick={() => resetErrorMessage()}>Zamknij komunikat i połącz się ponownie</button>
                </Center>
            </Row>
        );
    }

    const resetErrorMessage = () => {
        setErrorOccurred(false);
        setErrorMessage('');
        console.log('zresetowałeś błędy...');
    }

    let UserVideo;
    if (stream) {
        UserVideo = (
            <Video playsInline muted ref={userVideo} autoPlay/>
        );
    }

    let PartnerVideo;
    if (callAccepted && !callEnded) {
        PartnerVideo = (
            <Video playsInline ref={partnerVideo} autoPlay/>
        );
    }

    // const clickTest = (id) => {
    //
    // }

    return (
        <ContainerFluid>
            <Row>
                <ColPlayer>
                    {callAccepted && (
                        <Center>
                            {PartnerVideo}
                            {endCallButton}
                        </Center>
                    )}
                </ColPlayer>
                <ColUser>
                    {stream && (
                        <div>
                            {UserVideo}
                            <button onClick={() => muteAudio()}>
                                {muted && (
                                    <span>Włącz</span>
                                )}
                                {!muted && (
                                    <span>Wyłącz</span>
                                )} audio
                            </button>
                            <button onClick={() => hideVideo()}>
                                {hideVideoTrack && (
                                    <span>Włącz</span>
                                )}
                                {!hideVideoTrack && (
                                    <span>Wyłącz</span>
                                )} video
                            </button>
                        </div>
                    )}
                </ColUser>
            </Row>
            {somethingBad}
            {usersContainer}
            <Row>
                {outgoingCall}
                {incomingCall}
            </Row>
            {callAccepted && (
                <Row>
                    <ChatContainer addressee={addressee} sender={yourID} errorOccurred={errorOccurred}/>
                </Row>
            )}
            {/*<Row>*/}
            {/*    <button onClick={() => clickTest(1234567)}>Test</button>*/}
            {/*</Row>*/}
        </ContainerFluid>
    );
}

export default VideoComponent;