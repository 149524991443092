const namespace = 'WELCOME';
export const SET_USER = `SET_USER_${namespace}`;
export const GET_USER = `GET_USER_${namespace}`;

export const setUser = (uuid) => {
    localStorage.setItem("uuid", JSON.stringify(uuid));

    return function (dispatch) {
        dispatch({
            type: SET_USER,
            payload: true
        });
    }
}

export const getUser = () => {
    return function (dispatch) {
        dispatch({
            type: GET_USER,
            payload: JSON.parse(localStorage.getItem("uuid"))
        });
    }
}