import React, {useEffect, useState} from "react";
import {Redirect} from "react-router-dom";

const WelcomeComponent = (props) => {
    const [uuid, setUuid] = useState(false);

    useEffect(() => {
        if (props.uuid) {
            setUuid(true);
        }
    }, [props.uuid]);

    const setUser = uuid => {
        props.setUser(uuid);
        props.getUser();
    }

    if (uuid) {
        return <Redirect to={'/video'} />
    }

    return (
        <div>
            <button onClick={() => setUser('c7759686-b103-4fb0-a6e4-86f3e8432053')}>Jestem Jarek</button>
            <button onClick={() => setUser('9e14dc9f-52fb-4a7e-bdf0-df09c57ad243')}>Jestem Piotr</button>
            <button onClick={() => setUser('f24caabb-3c64-450d-8d10-1d63f5bd1c15')}>Jestem Witold</button>
        </div>
    );
}

export default WelcomeComponent;