import React, {useEffect, useRef, useState} from "react";
import {config} from "../../../config/config";
import './ChatRoom.css';

const ChatComponent = (props) => {
    const [audioMessage] = useState(new Audio(config.FRONT + '/message.mp3'));
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState("");

    const socket = useRef();

    useEffect(() => {

        if (props.errorOccurred) {
            setMessages([]);
        }

        socket.current = props.socket;

        socket.current.on("newChatMessage", (message) => {
            if (message.to === socket.current.id) {
                audioMessage.play();
                setTimeout(() => {
                    audioMessage.pause();
                    audioMessage.currentTime = 0;
                }, 300);
            }
            const incomingMessage = {
                ...message,
                ownedByCurrentUser: message.senderId === socket.current.id
            };
            setMessages((messages) => [...messages, incomingMessage]);
        });
    }, []);

    const sendMessage = (messageBody) => {
        socket.current.emit("newChatMessage", {
            to: props.addressee,
            body: messageBody,
            senderId: props.sender,
        });
    };

    const handleNewMessageChange = (event) => {
        setNewMessage(event.target.value);
    };

    const handleSendMessage = () => {
        sendMessage(newMessage);
        setNewMessage("");
    };

    return (
        <div className="chat-room-container">
            <div className="messages-container">
                <ol className="messages-list">
                    {messages.map((message, i) => (
                        <li
                            key={i}
                            className={`message-item ${
                                message.ownedByCurrentUser ? "my-message" : "received-message"
                            }`}
                        >
                            {message.body}
                        </li>
                    ))}
                </ol>
            </div>
            <textarea
                value={newMessage}
                onChange={handleNewMessageChange}
                placeholder="Napisz wiadomość..."
                className="new-message-input-field"
            />
            <button onClick={handleSendMessage} className="send-message-button">
                Wyślij
            </button>
        </div>
    );
}

export default ChatComponent;