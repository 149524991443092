import io from "socket.io-client";
import {config} from "../../../config/config";

const namespace = 'SOCKET';
export const SOCKET_INIT = `SOCKET_INIT_${namespace}`;

export const socketInit = () => {
    const user = JSON.parse(localStorage.getItem("uuid"));

    const connectionOptions = {
        "force new connection": true,
        "reconnectionAttempts": "Infinity",
        "timeout": 10000,
        "transports": ["websocket"],
        "query": `uuid=${user}`
    };

    const socket = io.connect(config.ENDPOINT, connectionOptions);

    return function (dispatch) {
        dispatch({
            type: SOCKET_INIT,
            payload: socket,
        });
    }
}