import {SOCKET_INIT} from "../actions/socketAction";

const INITIAL_STATE = {
    socket: '',
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case SOCKET_INIT:
            return {
                ...state,
                socket: action.payload,
            }
        default:
            return state;
    }
}