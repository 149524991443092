import React from "react";
import {connect} from "react-redux";
import ChatComponent from "../components/chatComponent";
import {sendMessage} from "../actions/chatAction";

const chatContainer = (props) => {
    return (
        <ChatComponent {...props} />
    );
}

const mapStateToProps = state => {
    return {
        messages: state.chatReducer.messages,
        isLoading: state.chatReducer.isLoading,
        socket: state.socketReducer.socket,
    }
}

const mapDispatchToProps = dispatch => ({
    sendMessage : (socket, message, from, to) => {
        dispatch(sendMessage(socket, message, from, to));
    },
    // onStart: () => {
    //     dispatch(doopaStart());
    // }
});

export default connect(mapStateToProps, mapDispatchToProps)(chatContainer);