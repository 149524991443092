import React from "react";
import styled from "styled-components";

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const TeacherComponent = (props) => {
    return (
        <Row>
            {Object.keys(props.users).map(key => {
                if (key === props.yourID || props.users[key].user.isStudent) {
                    return null;
                }

                let statusClass, statusLabel, callButton;
                if (props.users[key].ongoingCall) {
                    statusClass = "user-container offline";
                    statusLabel = "Zajęty";
                    callButton = "";
                } else {
                    statusClass = "user-container online";
                    statusLabel = "Dostępny";
                    if (!props.blockPhone && !props.receivingCall && props.loggedUser.isStudent) {
                        callButton = <button className="cta phone mat-mini-fab mat-button-base mat-primary"
                                             onClick={() => props.callPeer(key)}>
            <span className="mat-button-wrapper">
                Zadzwoń do {props.users[key].user.name} {props.users[key].user.surname}
            </span>
                            <div className="mat-button-ripple mat-ripple mat-button-ripple-round"/>
                            <div className="mat-button-focus-overlay"/>
                        </button>;
                    }
                }

                return (
                    <div className={statusClass} key={key}>
                        <div className="status">
                            <span className="dot ng-star-inserted"> </span>
                            <span className="status-text"> {statusLabel} </span>
                        </div>
                        <div className="avatar ng-star-inserted">
                            <img
                                src={'https://language.4client.com.pl/uploads/avatars/' + props.users[key].user.avatar}
                                alt={props.users[key].user.name + ' ' + props.users[key].user.surname}/>
                        </div>
                        <div className="details ng-star-inserted">
                            <div className="name">{props.users[key].user.name} {props.users[key].user.surname}</div>
                        </div>
                        <div className="overlay">
                            {callButton}
                        </div>
                    </div>
                );
            })}
        </Row>
    );
}

export default TeacherComponent;