import React from "react";
import {connect} from "react-redux";
import {socketInit} from "../../Socket/actions/socketAction";
import VideoComponent from "../components/VideoComponent";
import {getLoggedUser} from "../actions/videoAction";

const VideoContainer = (props) => {
    return (
        <VideoComponent {...props} />
    );
}

const mapStateToProps = state => {
    return {
        socket: state.socketReducer.socket,
        uuid: state.welcomeReducer.uuid
    }
}

const mapDispatchToProps = dispatch => ({
    socketInit: () => {
        dispatch(socketInit());
    },
    getLoggedUser: (uuid) => {
        dispatch(getLoggedUser(uuid));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(VideoContainer);