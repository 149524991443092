import React from "react";
import {connect} from "react-redux";
import WelcomeComponent from "../components/welcomeComponent";
import {getUser, setUser} from "../actions/welcomeAction";

const WelcomeContainer = (props) => {
    return (
        <WelcomeComponent {...props}/>
    );
}

const mapStateToProps = state => {
    return {
        uuid: state.welcomeReducer.uuid,
    }
}

const mapDispatchToProps = dispatch => ({
    setUser: (uuid) => {
        dispatch(setUser(uuid));
    },
    getUser: () => {
        dispatch(getUser());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeContainer);