const namespace = 'CHAT';
export const SEND_MESSAGE = `SEND_MESSAGE_${namespace}`;

export const sendMessage = (socket, message, from, to) => {
    return function (dispatch) {
        dispatch({
            type: SEND_MESSAGE,
            payload: {}
        });
    }
}