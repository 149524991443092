import React from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import NotFound from './NotFound';
// import Welcome from "./Welcome";
import VideoContainer from "./modules/Video/containers/VideoContainer";
import WelcomeContainer from "./modules/Welcome/containers/welcomeContainer";
// import VideoComponent from "./VideoComponent";

const Router = () => {
    return (
        <BrowserRouter>
            <Switch>
                {/*<Route exact path={["/", "/home"]} component={Welcome}/>*/}
                <Route exact path={["/", "/home"]} component={WelcomeContainer}/>
                <Route exact path="/video" component={() => <VideoContainer />}/>
                {/*<Route exact path="/video" component={() => <VideoComponent />}/>*/}
                <Route component={NotFound}/>
            </Switch>
        </BrowserRouter>
    );
}

export default Router;