import {SEND_MESSAGE} from "../actions/chatAction";

const INITIAL_STATE = {
    messages: [],
    isLoading: false,
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case SEND_MESSAGE:
            return {
                ...state,
                socket: action.payload,
            }
        default:
            return state;
    }
}