import {GET_USER, SET_USER} from "../actions/welcomeAction";

const INITIAL_STATE = {
    uuid: '',
    success: false
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_USER:
            return {
                ...state,
                success: action.payload,
            }
        case GET_USER:
            return {
                ...state,
                uuid: action.payload,
            }
        default:
            return state;
    }
}